import { render, staticRenderFns } from "./auctionRoutes.vue?vue&type=template&id=e38e0044&scoped=true&"
import script from "./auctionRoutes.vue?vue&type=script&setup=true&lang=js&"
export * from "./auctionRoutes.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./auctionRoutes.vue?vue&type=style&index=0&id=e38e0044&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e38e0044",
  null
  
)

export default component.exports