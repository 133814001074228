import { render, staticRenderFns } from "./ListRoutes.vue?vue&type=template&id=039c2b12&scoped=true&"
import script from "./ListRoutes.vue?vue&type=script&setup=true&lang=js&"
export * from "./ListRoutes.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./ListRoutes.vue?vue&type=style&index=0&id=039c2b12&prod&lang=scss&scoped=true&"
import style1 from "./ListRoutes.vue?vue&type=style&index=1&id=039c2b12&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "039c2b12",
  null
  
)

export default component.exports