<template>
	<div>
		<div class="bg-white border-bottom">
			<div class="header mb-0">
				<div class="container-fluid">
					<div class="header-body py-0 mt-4 border-0">
						<div class="row align-items-end">
							<div class="col">
								<h6 class="header-pretitle">Company Overview</h6>
								<h1 class="header-title">
									{{ currentCompany ? currentCompany.corporate_name : '' }}
								</h1>
							</div>
							<div class="col-auto">
								<div class="dropdown">
									<button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
										Actions
									</button>
									<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
										<router-link v-if="userHasPermission('corporates004')" :to="{
											name: 'EditCompany',
											params: { companyId: companyId },
										}" class="dropdown-item">Update Information</router-link>
										<a v-if="userHasPermission('corporates006')" class="dropdown-item py-2" href="#" @click.prevent="openWalletPaymentOptions()">Wallet payment options</a>
										<a class="dropdown-item py-2" href="#" @click.prevent="is_group_modal = true">Create Group</a>

										<a class="dropdown-item py-2" href="#" @click.prevent="openCancellationModal()" v-if="userHasPermission('corporates007')">Booking Cancellation Config</a>
										<a class="dropdown-item py-2" href="#" @click.prevent="openDateSelectionModal()">Export manifest</a>
										<a v-if="userHasPermission('corporates005')" class="dropdown-item py-2" :class="isCompanyActive ? 'text-danger' : 'text-success'" href="#" @click.prevent="suspendOrUnsuspendCompany">{{
											isCompanyActive ? 'Suspend' : 'Unsuspend'
										}}
											Company</a>
									</div>
								</div>
							</div>
						</div>
						<!-- / .row -->
					</div>
					<!-- / .header-body -->
				</div>
			</div>
			<div class="container-fluid">
				<div class="row pt-3 pb-1">
					<div class="col justify-self-center align-self-center flex-column">
						<ul class="nav nav-pills">
							<li class="nav-item" v-if="isInActiveCompany || isActiveCompany">
								<router-link :to="{
									name: 'ShowCompanyInfo',
									props: { companyId: $route.params.companyId, status: 'active' },
								}" class="nav-link" active-class="active">Company Info</router-link>
							</li>

							<li class="nav-item" v-if="isActiveCompany">
								<router-link :to="{
									name: 'ShowCorporateStats',
									props: { companyId: $route.params.companyId },
								}" class="nav-link" active-class="active">Stats</router-link>
							</li>

							<li class="nav-item" v-if="isInActiveCompany || isActiveCompany">
								<router-link :to="{
									name: 'ShowCompanyStaff',
									props: { companyId: $route.params.companyId },
								}" class="nav-link" active-class="active">Staff</router-link>
							</li>
							<li class="nav-item" v-if="isInActiveCompany || isActiveCompany">
								<router-link :to="{
									name: 'ShowCompanyWallet',
									props: { companyId: $route.params.companyId },
								}" class="nav-link" active-class="active">Wallet</router-link>
							</li>
							<li class="nav-item" v-if="isActiveCompany">
								<router-link :to="{
									name: 'CompanyTripReport',
									props: { companyId: $route.params.companyId },
								}" class="nav-link" active-class="active">Trip Report</router-link>
							</li>
							<li class="nav-item" v-if="isActiveCompany || isInActiveCompany">
								<router-link :to="{
									name: 'CompanyGroups',
									props: { companyId: $route.params.companyId },
								}" class="nav-link" active-class="active">Groups</router-link>
							</li>
							<li class="nav-item" v-if="isActiveCompany">
								<router-link :to="{
									name: 'ShowCreditLine',
									props: { companyId: $route.params.companyId },
								}" class="nav-link" active-class="active">Credit Line</router-link>
							</li>

							<li class="nav-item" v-if="isActiveCompany">
								<router-link :to="{
									name: 'ShowCompanyCreditLineUsages',
									props: { companyId: $route.params.companyId },
								}" class="nav-link" active-class="active">Credit Line Usages</router-link>
							</li>

							<li class="nav-item" v-if="isActiveCompany">
								<router-link :to="{
									name: 'CapacityConfiguration',
									props: { companyId: $route.params.companyId },
								}" class="nav-link" active-class="active">Capacity Configuration</router-link>
							</li>

							<li class="nav-item" v-if="isActiveCompany">
								<router-link :to="{
									name: 'CorporateRoutes',
									props: { companyId: $route.params.companyId },
								}" class="nav-link" active-class="active">Routes</router-link>
							</li>
							<li class="nav-item" v-if="isActiveCompany && DoNotShowOnProd()">
								<router-link :to="{
									name: 'ShowCompanyRequest',
									props: { companyId: $route.params.companyId },
								}" class="nav-link" active-class="active">Requests</router-link>
							</li>

							<li class="nav-item" v-if="isActiveCompany">
								<router-link :to="{
									name: 'ShowCompanyCorporatePay',
									props: { companyId: $route.params.companyId },
								}" class="nav-link" active-class="active">Corporate Pay</router-link>
							</li>

							<li class="nav-item" v-if="isActiveCompany">
								<router-link :to="{
									name: 'ShowCompanySettings',
									props: { companyId: $route.params.companyId },
								}" class="nav-link" active-class="active">Staff Settings</router-link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="pt-4">
			<div class="container-fluid">
				<!-- <button
          @click="$router.go(-1)"
          class="btn btn-light mb-4 btn-sm"
          v-if="!loading"
        >
          Go back
        </button> -->
				<router-link :to="{ name: 'Companies' }" class="btn btn-light mb-4 btn-sm" v-if="!loading && isInActiveCompany">Go back</router-link>
				<router-view></router-view>
			</div>
		</div>

		<update-confirmation-modal indexKey="walletOptionsModal" :is-password-type="true" :show="showUpdateWalletOptions" modal-title="Corporate Wallet Options" :update-function="saveWalletOptionUpdate" @close="closeWalletPaymentOptions" :processing="savingWalletOptionChanges"
			prompt-text="Please enter your password to save your changes" prompt-key="your password" verification-message="Are you sure you want to save this changes ?" :can-proceed="true">
			<template>
				<div class="form-group mb-3">
					<b-form-checkbox class="v-checkbox" v-model="walletOptionsForm.publicRoutesRestricted">
						Restrict bookings on public routes
					</b-form-checkbox>
				</div>
				<div class="form-group mb-3">
					<b-form-checkbox class="v-checkbox" v-model="walletOptionsForm.publicRoutesRestrictedWithCorpWallet">
						Restrict booking on public routes with company wallet
					</b-form-checkbox>
				</div>
				<div class="form-group mb-3">
					<b-form-checkbox class="v-checkbox" v-model="walletOptionsForm.privateRoutesRestrictedWithPersonalWallet
						">
						Restrict booking on private routes with personal wallet
					</b-form-checkbox>
				</div>
				<div class="form-group mb-3">
					<b-form-checkbox class="v-checkbox" v-model="walletOptionsForm.privateRoutesRestrictedWithCorporateWallet
						">
						Restrict booking on private routes with company wallet
					</b-form-checkbox>
				</div>
				<div class="form-group mb-3">
					<b-form-checkbox class="v-checkbox" v-model="walletOptionsForm.restrict_booking_with_corporate_wallet_from_staff
						">
						Restrict booking with corporate wallet from staff
					</b-form-checkbox>
				</div>
				<div class="form-group mb-3">
					<b-form-checkbox class="v-checkbox" v-model="walletOptionsForm.restrict_staff_booking_on_private_route_with_corp_wallet
						">
						Restrict staff booking on private route with corp wallet
					</b-form-checkbox>
				</div>
				<div class="form-group mb-3">
					<b-form-checkbox class="v-checkbox" v-model="walletOptionsForm.restrict_staff_booking_on_public_route_with_corp_wallet
						">
						Restrict staff booking on public route with corp wallet
					</b-form-checkbox>
				</div>
			</template>
		</update-confirmation-modal>

		<topup-wallet-modal :show="showTopupWallet" :wallet="wallet" @close="showTopupWallet = false" @wallet-funded="fetchCompanyWallet"></topup-wallet-modal>

		<update-confirmation-modal indexKey="bookingCancellationModal" :is-password-type="true" :show="showBookingCancellationModal" modal-title="Booking cancellations modal" :update-function="saveBookingCancellationOptionUpdate" @close="closeCancellationModal"
			:processing="savingBookingCancellationOptionChanges" prompt-text="Please enter your password to save your changes" prompt-key="your password" verification-message="Are you sure you want to save this changes ?" :can-proceed="true">
			<template>
				<div class="form-group mb-3">
					<b-form-checkbox class="v-checkbox" v-model="bookingCancellationOptionsForm.restrict_admin_bookings_cancellation
						">
						Enable booking cancellation on admin
					</b-form-checkbox>
				</div>
			</template>
		</update-confirmation-modal>

		<b-modal :id="modalId" @close="closeDateSelectionModal" size="sm" no-close-on-backdrop no-close-on-esc>
			<template #modal-title> Manifest report (Select date range) </template>
			<div class="py-2">
				<div class="form">
					<div class="form-group">
						<label class="form-label mb-0">Start Date</label>
						<div style="width: 100%">
							<v-datepicker v-model="form.startDate" style="width: 100%"></v-datepicker>
						</div>
					</div>

					<div class="form-group">
						<label class="form-label mb-0">End Date</label>
						<div style="width: 100%">
							<v-datepicker v-model="form.endDate" style="width: 100%"></v-datepicker>
						</div>
					</div>
				</div>
			</div>
			<template #modal-footer>
				<div class="w-100">
					<b-button :disabled="downloadingReport" variant="primary" class="float-right" @click="generateCustomRangeReport()">
						{{ downloadingReport ? 'Processing' : 'Generate' }}
					</b-button>
					<b-button :disabled="downloadingReport" variant="secondary" class="float-right mr-2" @click="closeDateSelectionModal()">
						Close
					</b-button>
				</div>
			</template>
		</b-modal>
		<BS_modal :show='is_group_modal' title='Create Group' @close='is_group_modal = false' modalId='is_group_modal'>
			<div class="form-group mb-3">
				<label class="form-label">Group Name</label>
				<input class="form-control" v-model='groupName' />
			</div>
			<!-- <div class="form-group mb-3">
				<div class="">
					<label for="">Select staff</label>
					<multiselect v-model="users" id="select_users_input" :custom-label="({ fname, lname }) => `${fname} ${lname}`" track-by="id" placeholder="Type to search" open-direction="bottom" :options="usersList" :multiple="true" :searchable="true" :loading="isLoading" :internal-search="false"
						:clear-on-select="false" :close-on-select="false" :options-limit="300" :limit="10" :show-no-results="false" :hide-selected="false" @search-change="asyncFindUsers">
						<template slot="clear">
							<div class="multiselect__clear" v-if="users?.length" @mousedown.prevent.stop="users = []">
							</div>
						</template><span slot="noResult">Oops! No users found. Consider changing the search
							query.</span>
					</multiselect>
				</div>
			</div> -->

			<b-button :disabled="createLoading || groupName.length === 0" variant="primary" class='w-full mt-4' @click="createGroup()">
				{{ createLoading ? 'loading...' : 'Create' }}
			</b-button>
		</BS_modal>
	</div>
</template>


<script setup>
import { userHasPermission, DoNotShowOnProd } from '@/composables/core/permissions'
import { onMounted, ref, defineProps, computed, reactive } from 'vue'
import { axiosInstance } from '@/plugins/axios'
import Multiselect from 'vue-multiselect'
import BS_modal from '@/components/core/modals/BS_modal.vue'

const is_group_modal = ref(false)
const isLoading = ref(false)
const createLoading = ref(false)
const groupName = ref('')
const users = ref([])
const usersList = ref([])

const props = defineProps({
	companyId: {
		required: true,
		type: [Number, String]
	}
})
// users: users.value.map((user) => user.id)
const createGroup = () =>
{
	createLoading.value = true
	axiosInstance.post(`/v1/corporates/${props.companyId}/groups`, { name: groupName.value })
		.then((res) =>
		{
			Swal.fire({
				icon: 'success',
				title: 'Group created',
				text: 'Group created successfully',
				showCloseButton: true
			}).then(() =>
			{
				window.location.reload()
				is_group_modal.value = false
				createLoading.value = false
				groupName.value = ''
				users.value = []
			})

		})
		.catch((e) =>
		{
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'An error occurred while creating group',
			})
			createLoading.value = false
			is_group_modal.value = false
			groupName.value = ''
			users.value = []
		})
}

</script>

<script>
import Swal from 'sweetalert2'
import TopupWalletModal from '@/components/modals/TopupWalletModal.vue'
import UpdateConfirmationModal from '@/components/modals/UpdateConfirmationModal'
import { extractErrorMessage } from '@/utils/helpers'
import { format } from 'date-fns'
export default {
	props: {
		companyId: {
			required: true,
			type: [Number, String]
		}
	},
	components: {
		UpdateConfirmationModal,
		TopupWalletModal
	},
	data()
	{
		return {
			currentCompany: null,
			company: null,
			migratingWallet: false,
			processingExecution: false,
			loading: true,
			errorLoading: false,
			currentCreditSystem: null,
			needCreditSystem: false,
			error: null,
			companies: null,
			wallet: null,
			walletHistory: null,
			loadingCompanyWallet: true,
			errorLoadingCompanyWallet: false,
			bookingCancellationOptionsForm: {
				restrict_admin_bookings_cancellation: null,
				verification_password: ''
			},
			walletHistoryPerPage: 20,
			walletHistoryCurrentPage: 1,
			showTopupWallet: false,
			showUpdateWalletOptions: false,
			showBookingCancellationModal: false,
			savingWalletOptionChanges: false,
			savingBookingCancellationOptionChanges: false,
			walletOptionsForm: {
				publicRoutesRestricted: null,
				publicRoutesRestrictedWithCorpWallet: null,
				privateRoutesRestrictedWithPersonalWallet: null,
				privateRoutesRestrictedWithCorporateWallet: null,
				restrict_booking_with_corporate_wallet_from_staff: null,
				restrict_staff_booking_on_private_route_with_corp_wallet: null,
				restrict_staff_booking_on_public_route_with_corp_wallet: null,


			},
			processing: false,
			passwordConfirmation: 'passwordConfirmation',
			cancellationOptionsModal: 'bookingCancellationOptionsModal',
			modalId: 'manifest-report-generation-modal',
			form: {
				startDate: new Date(
					`${new Date().getFullYear()}-${new Date().getMonth() + 1}-1`
				),
				endDate: new Date()
			},
			downloadingReport: false
		}
	},
	computed: {
		isActiveCompany()
		{
			return this.$route.params.status === 'active' ? true : true // aftering finding another criteria for new signups, introduce false
		},
		isInActiveCompany()
		{
			return this.$route.params.status === 'inactive' ? true : true // aftering finding another criteria for new signups, introduce false
		},
		isCompanyActive()
		{
			if (!this.currentCompany) {
				return false
			}

			return !!parseInt(this.currentCompany.active)
		}
	},
	created()
	{
		this.fetchCompany(this.companyId)
	},
	mounted()
	{
		this.fetchCompany(this.companyId)
	},
	methods: {
		fetchCompanyWallet()
		{
			this.loadingCompanyWallet = true
			this.errorLoadingCompanyWallet = false

			this.axios
				.get(`/v1/users/${this.companyId}/wallet`)
				.then((res) =>
				{
					this.wallet = res.data.wallet
					this.walletHistory = res.data.walletHistory
				})
				.catch(() => (this.errorLoadingCompanyWallet = true))
				.finally(() => (this.loadingCompanyWallet = false))
		},
		openDateSelectionModal()
		{
			this.$bvModal.show(this.modalId)
		},
		closeDateSelectionModal()
		{
			this.$bvModal.hide(this.modalId)
			this.form = {
				startDate: new Date(
					`${new Date().getFullYear()}-${new Date().getMonth() + 1}-1`
				),
				endDate: new Date()
			}
		},
		fetchAndDownloadReport(params, customRange = false)
		{
			this.downloadingReport = true
			return this.axios
				.post(`/v1/corporates-report/${this.companyId}/route-manifest`, {
					start_date: params.start_date,
					end_date: params.end_date
				})
				.then((res) =>
				{
					const result = this.handleZipDownload(res.data.url)
				})
				.catch(() =>
				{
					this.$swal({
						icon: 'error',
						title: 'Download error',
						text: 'We could not process download for this report',
						showCloseButton: true
					})
				})
				.finally(() =>
				{
					this.downloadingReport = false
					if (customRange) {
						this.closeDateSelectionModal()
					}
				})
		},
		handleZipDownload(url)
		{
			const link = document.createElement('a')
			link.href = url

			link.setAttribute('target', '_blank')

			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
			this.$swal({
				icon: 'success',
				title: 'Download success',
				text: 'Report was successfully downloaded',
				showCloseButton: true
			})
		},
		generateCustomRangeReport()
		{
			const data = {
				start_date: format(this.form.startDate, 'yyyy-MM-dd'),
				end_date: format(this.form.endDate, 'yyyy-MM-dd')
			}
			this.fetchAndDownloadReport(data, true)
		},
		resetOptions()
		{
			this.bookingCancellationOptionsForm.restrict_admin_bookings_cancellation =
				null
		},
		resetForm()
		{
			this.form.verification_password = ''
		},
		closePasswordConfirmationModal()
		{
			this.$bvModal.hide(this.passwordConfirmation)
			this.resetForm()
			this.resetOptions()
		},
		fetchCompany(companyId)
		{
			this.loading = true
			this.axios
				.get(`/v1/corporates/${companyId}`)
				.then((res) =>
				{
					this.currentCompany = res.data
					this.loading = false
					this.fetchCompanyWallet()
					this.setWalletPaymentOptions()
				})
				.catch(() =>
				{
					this.loading = false
				})
				.finally(
					() => this.setBookingCancellationOptions(),

				)
		},
		openWalletPaymentOptions()
		{
			this.showUpdateWalletOptions = true
			this.setWalletPaymentOptions()
		},
		closeWalletPaymentOptions()
		{
			this.showUpdateWalletOptions = false
		},
		openCancellationModal()
		{
			this.showBookingCancellationModal = true
			this.setBookingCancellationOptions()
		},
		closeCancellationModal()
		{
			this.showBookingCancellationModal = false
		},
		saveWalletOptionUpdate(confirmationParams)
		{
			if (!confirmationParams.isVerificationStep) {
				confirmationParams.gotoVerification()
			} else {
				const payload = {
					restrict_booking_on_public_routes:
						!!this.walletOptionsForm.publicRoutesRestricted,
					restrict_booking_on_public_routes_with_corporate_wallet:
						!!this.walletOptionsForm.publicRoutesRestrictedWithCorpWallet,
					restrict_booking_on_private_routes_with_personal_wallet:
						!!this.walletOptionsForm.privateRoutesRestrictedWithPersonalWallet,
					restrict_booking_on_private_routes_with_corporate_wallet:
						!!this.walletOptionsForm.privateRoutesRestrictedWithCorporateWallet,
					restrict_booking_with_corporate_wallet_from_staff:
						!!this.walletOptionsForm.restrict_booking_with_corporate_wallet_from_staff,
					restrict_staff_booking_on_private_route_with_corp_wallet:
						!!this.walletOptionsForm.restrict_staff_booking_on_private_route_with_corp_wallet,
					restrict_staff_booking_on_public_route_with_corp_wallet:
						!!this.walletOptionsForm.restrict_staff_booking_on_public_route_with_corp_wallet,
					verification_password: confirmationParams.passKey
				}
				this.savingWalletOptionChanges = true
				this.axios
					.patch(`v1/corporates/${this.companyId}`, payload)
					.then(() => this.fetchCompany())
					.then(() =>
					{
						this.showUpdateWalletOptions = false
						this.$swal({
							icon: 'success',
							title: 'Success',
							text: 'Changes saved successfully!',
							showCloseButton: true
						})
					})
					.catch((e) =>
					{
						const msg = extractErrorMessage(
							e,
							'An error occurred. Please try again later'
						)
						this.$toastr.e(msg)
					})
					.finally(() =>
					{
						this.savingWalletOptionChanges = false
					})
			}
		},
		saveBookingCancellationOptionUpdate(confirmationParams)
		{
			if (!confirmationParams.isVerificationStep) {
				confirmationParams.gotoVerification()
			} else {
				const payload = {
					restrict_admin_bookings_cancellation:
						this.bookingCancellationOptionsForm
							.restrict_admin_bookings_cancellation,
					verification_password: confirmationParams.passKey
				}
				this.savingBookingCancellationOptionChanges = true
				this.axios
					.patch(`v1/corporates/${this.companyId}`, payload)
					.then(() => this.fetchCompany())
					.then(() =>
					{
						this.showBookingCancellationModal = false
						this.$swal({
							icon: 'success',
							title: 'Success',
							text: 'Changes saved successfully!',
							showCloseButton: true
						})
					})
					.catch((e) =>
					{
						const msg = extractErrorMessage(
							e,
							'An error occurred. Please try again later'
						)
						this.$toastr.e(msg)
					})
					.finally(() =>
					{
						this.savingBookingCancellationOptionChanges = false
					})
			}
		},
		beforeDestroy()
		{
			this.closeDateSelectionModal()
		},
		setWalletPaymentOptions()
		{
			if (this.currentCompany) {
				this.walletOptionsForm.publicRoutesRestricted =
					!!this.currentCompany.restrict_booking_on_public_routes
				this.walletOptionsForm.publicRoutesRestrictedWithCorpWallet =
					!!this.currentCompany.restrict_booking_on_public_routes_with_corporate_wallet
				this.walletOptionsForm.privateRoutesRestrictedWithPersonalWallet =
					!!this.currentCompany.restrict_booking_on_private_routes_with_personal_wallet
				this.walletOptionsForm.privateRoutesRestrictedWithCorporateWallet =
					!!this.currentCompany
						.restrict_booking_on_private_routes_with_corporate_wallet
				this.walletOptionsForm.restrict_booking_with_corporate_wallet_from_staff =
					!!this.currentCompany
						.restrict_booking_with_corporate_wallet_from_staff
				this.walletOptionsForm.restrict_staff_booking_on_private_route_with_corp_wallet =
					!!this.currentCompany
						.restrict_staff_booking_on_private_route_with_corp_wallet
				this.walletOptionsForm.restrict_staff_booking_on_public_route_with_corp_wallet =
					!!this.currentCompany
						.restrict_staff_booking_on_public_route_with_corp_wallet
			}
		},
		setBookingCancellationOptions()
		{
			if (this.company) {
				this.bookingCancellationOptionsForm.restrict_admin_bookings_cancellation =
					!!this.company.restrict_admin_bookings_cancellation
			}
		},
		async suspendOrUnsuspendCompany()
		{
			const isSuspendAction = this.isCompanyActive

			await this.$swal({
				icon: 'question',
				title: 'Please Confirm',
				text: `Are you sure you want to ${this.isCompanyActive ? 'suspend' : 'unsuspend'
					} this company?`,
				showConfirmButton: true,
				showCancelButton: true,
				preConfirm: () =>
				{
					return this.axios
						.patch(`/v1/corporates/${this.companyId}`, {
							active: this.isCompanyActive ? 0 : 1
						})
						.then((res) =>
						{
							this.company = res.data

							return res.data
						})
						.catch((error) =>
						{
							const msg =
								error.response && error.response.data
									? error.response.data.message
									: 'An error occured, please try again.'

							this.$swal().showValidationMessage(msg)
						})
				},
				allowOutsideClick: () => !Swal.isLoading()
			}).then((result) =>
			{
				if (result.isConfirmed) {
					this.$swal({
						icon: 'success',
						title: 'Success',
						text: `Company has been ${isSuspendAction ? 'suspended' : 'unsuspended'
							} successfully`,
						showCloseButton: true
					})
				}
			})
		},
		viewWalletTransaction(transaction)
		{
			this.$router.push({
				name: 'transactions.view',
				params: { transactionId: transaction.id }
			})
		}
	}
}
</script>

<style lang="scss" scoped>
$primary: #01e069 !default;
$black: #060606 !default;

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
	background-color: transparent;
	box-shadow: 0 3px 0 0px $primary;
	color: $black;
	font-weight: 800;
}

.nav-pills .nav-link,
.nav-pills .show>.nav-link {
	border-radius: 0;
}

.VueTables__search-field>label {
	display: none;
}

.VueTables__search-field {
	margin-top: 20px;
}

.VueTables__limit {
	margin-right: 10px !important;
	padding-top: 10px;
}
</style>
