<template>
	<div class="p-3">
		<div v-if="loading" class="d-flex justify-content-center align-items-center">
			<div class="spinner-border" role="status"></div>
		</div>
		<div class="container-fluid" v-else>
			<div class="row" v-if="activeRoute">
				<div class="col-md-6 col-sm-12">
					<div class="card">
						<table class="table">
							<tbody>
								<tr v-if="activeRoute.is_exclusive">
									<td>Type</td>
									<td>
										<span class="badge badge-purple">
											Exclusive Route
										</span>
									</td>
								</tr>

								<tr>
									<td>Status</td>
									<td>
										<span class="badge" :class="activeRoute.status == '1'
											? 'badge-success'
											: 'badge-warning'
											">
											{{ activeRoute.status == '1' ? 'Active' : 'Inactive' }}
										</span>
									</td>
								</tr>

								<tr>
									<td>Route Code</td>
									<td>{{ activeRoute.route_code }}</td>
								</tr>
								<tr>
									<td>Route Description</td>
									<td>
										{{
											activeRoute.info
											? activeRoute.info.description || 'Not Available'
											: 'Not Available'
										}}
									</td>
								</tr>
								<tr>
									<td>Route landing page url</td>
									<td>
										<p v-if="routeUrl">
											<a :href="routeUrl" target="_blank">
												{{ routeUrl }}
											</a>
											<span title="Click to copy" class="ml-2 fe fe-copy cursor-pointer text-sm" @click="copyRouteUrl"></span>
										</p>
										<p v-else>
											{{ routeUrl || 'Not Available' }}
											<span class="ml-2 fe fe-copy text-sm" @click="copyRouteUrl"></span>
										</p>
									</td>
								</tr>
								<tr>
									<td>Starting Point</td>
									<td>{{ activeRoute.pickup }}</td>
								</tr>

								<tr>
									<td>Ending Point</td>
									<td>{{ activeRoute.destination }}</td>
								</tr>

								<tr>
									<td>Capacity</td>
									<td>{{ activeRoute.total_seats }}</td>
								</tr>

								<tr>
									<td>Days Available</td>
									<td class="text-capitalize">
										{{ activeRoute.availableDays }}
									</td>
								</tr>

								<tr>
									<td>Availability Start Date</td>
									<td class="text-capitalize">{{ routeStartDate }}</td>
								</tr>

								<tr>
									<td>Availability End Date</td>
									<td class="text-capitalize">{{ routeEndDate }}</td>
								</tr>

								<tr>
									<td>Unavailable Dates</td>
									<td class="text-capitalize text-orange-400">{{ routeUnavailableDays }}</td>
								</tr>

								<tr>
									<td>Distance</td>
									<td>{{ activeRoute.distance }}</td>
								</tr>
								<tr>
									<td>Trip start time</td>
									<td>{{ tripStartTime }}</td>
								</tr>
								<tr>
									<td>Duration</td>
									<td>{{ activeRoute.duration }}</td>
								</tr>

								<tr>
									<td>Availability</td>
									<td>
										<span class="badge" :class="activeRoute.visibility == 'public'
											? 'badge-success'
											: 'badge-primary'
											">
											{{ activeRoute.visibility }}
										</span>
									</td>
								</tr>

								<tr v-if="activeRoute.visibility == 'private'">
									<td>Business user</td>
									<td>
										<span>
											{{
												activeRoute.corporate
												? activeRoute.corporate.corporate_name
												: '-'
											}}
										</span>
									</td>
								</tr>

								<tr>
									<td>City</td>
									<td>{{ activeRoute.city ? activeRoute.city.name : '-' }}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="card">
						<div class="card-header">
							<div>
								<h4 class="card-header-title">Drivers on this route</h4>
							</div>
						</div>
						<div class="card-body">
							<div v-if="fetchingRouteDrivers" class="d-flex justify-content-center align-items-center">
								<div class="spinner-border" role="status"></div>
							</div>
							<div v-else>
								<table class="table table-borderless table-sm" v-if="routeDrivers.length > 0">
									<thead>
										<tr>
											<th>Driver</th>
											<th>Type</th>
											<th>Itinerary</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="driver in routeDrivers" :key="driver.id">
											<td>
												<router-link :to="{ name: 'ShowDriver', params: { driverId: driver.id } }" class="d-block">
													{{ `${driver.fname} ${driver.lname} - ${driver.email}` }}
												</router-link>
											</td>
											<td>
												<router-link v-if="driver.vehicle_id" :to="{ name: 'ShowVehicle', params: { vehicleId: driver.vehicle_id } }">
													<span>{{ driver.vehicle.type || 'N/A' }} {{ driver.vehicle.seats ? `• ${driver.vehicle.seats} seater` : '' }}</span>
												</router-link>
											</td>
											<td>{{ driver.itinerary.trip_time || 'N/A' }}</td>
										</tr>
									</tbody>
								</table>
								<div v-else>No drivers on this route</div>
							</div>
						</div>
					</div>

					<div class="mt-2">
						<RoutePaymentMode :route="activeRoute" />
					</div>
				</div>
				<div class="col-md-6 col-sm-12">
					<div class="card">
						<div class="card-header">
							<div>
								<h4 class="card-header-title">Passengers</h4>
							</div>
						</div>
						<div class="card-body">
							<div v-if="fetchingBookings" class="d-flex justify-content-center align-items-center">
								<div class="spinner-border" role="status"></div>
							</div>
							<div v-else>
								<div>
									<label class="form-label">Filter By Company</label>
									<v-select v-model="passengersFilter.corporateId" class="form-group" :options="companies" label="corporate_name" :reduce="(company) => company.id" placeholder="Select a company">
									</v-select>
								</div>
								<button type="button" class="btn btn-primary btn-sm">
									Apply Filter
								</button>
								<div class="mt-3">
									<b-table hover selectable responsive :items="weeklyBookings" :fields="passengersFields" :current-page="currentPage" :per-page="0" @row-clicked="showRouteBooking">
										<template #cell(route_day)="data">
											<span>{{ data.item.date | date('E') }}</span>
										</template>
										<template #cell(total_passengers)="data">
											<span>
												{{ data.item.passengers }}
											</span>
										</template>
										<template #cell(date)="data">
											<span>{{ data.item.date }}</span>
										</template>
									</b-table>
									<div class="">
										<p class="text-center header-pretitle mb-1">Weeks</p>
										<b-pagination v-model="currentPage" :total-rows="totalRowCount" :per-page="recordsPerPage" align="center">
										</b-pagination>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div>
						<route-report-widget :date-week="dateWeek" :active-route="activeRoute" :companies="companies">
						</route-report-widget>
					</div>
					<section class="card p-4">
						<header class="flex justify-between w-full">
							<h4 class="card-header-title">Payment Options</h4>
							<button type="button" class="btn btn-primary btn-sm" @click="isEditingPaymentOptions ? savePaymentOptions() : isEditingPaymentOptions = true" v-if='!isEditingPaymentOptionsLoading'>
								{{ isEditingPaymentOptions ? 'Save' : 'Edit' }}
							</button>
							<div v-else class="spinner-border mr-[20px] !w-3 !h-3"></div>
						</header>
						<div class="flex mt-3 gap-5 flex-wrap">
							<label :for="option.id" class='flex items-center' v-for='option in paymentOptions' :key='option.id'> <b-form-checkbox class="v-checkbox" :disabled='!isEditingPaymentOptions || isEditingPaymentOptionsLoading' :value="option.id" v-model='selectedPaymentOptions' /> {{ option.title }}</label>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import routeBusStop from '@/mixins/route_bus_stop'
import { getCurrentWeek, getDatesInMonth, getWeeklyBookings } from '../../utils/helpers'
import RouteReportWidget from '@/views/Routes/RouteReportWidget'
import { lookupService } from '@/services/lookup'
import { extractErrorMessage } from '@/utils/helpers'
import RoutePaymentMode from './RoutePaymentMode.vue'

export default {
	components: { RouteReportWidget, RoutePaymentMode },
	props: {
		routeId: {
			type: [String, Number],
			required: true
		}
	},
	mixins: [routeBusStop],
	data()
	{
		return {
			loading: true,
			isEditingPaymentOptionsLoading: false,
			isEditingPaymentOptions: false,
			paymentOptions: [],
			selectedPaymentOptions: [],
			activeRoute: null,
			tripStartTime: '-',
			passengers: [],
			bookings: [],
			weeklyBookings: [],
			dateWeek: null,
			routeDrivers: [],
			fetchingBookings: false,
			fetchingRouteDrivers: false,
			companies: [],
			passengersFilter: {
				corporateId: ''
			},
			passengersFields: [
				{
					key: 'route_day',
					label: 'Route Day'
				},
				{
					key: 'total_passengers',
					label: 'Total Passengers'
				},
				{
					key: 'date',
					label: 'Date'
				}
			],
			currentPage: 1,
			totalRowCount: 0,
			recordsPerPage: 7
		}
	},
	created()
	{
		this.getPaymentOptions()
		this.loadActiveRoute()
		this.fetchTripStartTime()
		this.fetchCompanies()

	},
	watch: {
		$route: {
			deep: true,
			handler()
			{
				if (this.activeRoute && this.$route.routeId != this.activeRoute.id) {
					this.loadActiveRoute()
				}
			}
		},
		'passengersFilter.corporateId'(value)
		{
			if (value) {
				this.getBookings(value)
			}
		},
		currentPage: {
			handler: function (page)
			{
				this.loadBookingForCurrentPage(page)
			}
		}
	},
	computed: {

		routeStartDate()
		{
			return this.activeRoute.route_availability_start_date ? new Date(this.activeRoute.route_availability_start_date).toDateString() : 'Not Available'
		},
		routeEndDate()
		{
			return this.activeRoute.route_availability_end_date ? new Date(this.activeRoute.route_availability_end_date).toDateString() : 'Not Available'
		},
		routeUnavailableDays()
		{
			const days = this.activeRoute.blacklisted_availability_days
			return days ? Array.from(JSON.parse(days) || []).join(', ') : 'Not Available'
		},
		getTripStartTime()
		{
			const route = this.activeRoute
			const itineraries = route.driver?.itineraries?.length
				? route.driver?.itineraries
				: null
			let tripStartTimeHrs = Infinity
			let tripStartTimeStr = '-'

			if (itineraries) {
				for (const itinerary of itineraries) {
					if (itinerary.trip_time_in_hours < tripStartTimeHrs) {
						tripStartTimeHrs = itinerary.trip_time_in_hours
						tripStartTimeStr = itinerary.trip_time
					}
				}
			}
			return tripStartTimeStr
		},
		routeUrl()
		{
			if (this.activeRoute?.slug) {
				return `${process.env.VUE_APP_URL}/routes/${this.activeRoute.slug}`
			} else {
				return ''
			}
		}
	},
	methods: {
		savePaymentOptions()
		{
			this.isEditingPaymentOptionsLoading = true
			// this.axios.post(`/v1/routes/${this.routeId}`, { route_id: this.routeId, payment_options_ids: this.selectedPaymentOptions }).then((res) =>
			this.axios.patch('/v1/bulk/route-payment-options', { route_id: this.routeId, payment_options_ids: this.selectedPaymentOptions }).then((res) =>
			{
				this.$toastr.s('Payment options updated successfully!')
				this.isEditingPaymentOptionsLoading = false
				this.isEditingPaymentOptions = false
			}).catch((err) =>
			{
				this.isEditingPaymentOptionsLoading = false
				this.isEditingPaymentOptions = false
				this.$toastr.e(extractErrorMessage(err))
			})

		},
		getPaymentOptions()
		{
			this.axios.get('/v1/payment-options').then((response) =>
			{
				this.paymentOptions = response.data.data
			})

			this.axios.get(`/v1/routes/${this.routeId}/payment-options`).then((response) =>
			{
				this.selectedPaymentOptions = response.data.map((item) => item.payment_options_id)
			})
		},
		async copyRouteUrl()
		{
			try {
				if (navigator.clipboard) {
					await navigator.clipboard.writeText(this.routeUrl)
				} else {
					const el = document.createElement('input')
					el.value = this.routeUrl
					el.style.opacity = 0
					document.body.appendChild(el)
					el.select()
					document.execCommand('copy')
					document.body.removeChild(el)
				}
				this.$toastr.Add({
					msg: 'Copied!',
					title: '',
					type: 'info',
					clickClose: false,
					timeout: 300,
					position: 'toast-top-center'
				})
			} catch (e) {
				(e)
			}
		},
		fetchCompanies()
		{
			lookupService.fetchCompanies().then((res) =>
			{
				this.companies = res
			})
		},
		async loadActiveRoute()
		{
			try {
				this.loading = true
				this.$Progress.start()
				const result = await this.axios.get(`/v1/routes/${this.routeId}`)
				const route = result.data
				route.showInfo = false
				this.showRoute(route)

				this.getPassengers()
				this.getBookings()

				this.getRouteDrivers()

				this.$Progress.finish()
			} catch (e) {
				this.$Progress.fail()
			} finally {
				this.loading = false
			}
		},
		async fetchTripStartTime()
		{
			let tripStartTime = '-'
			if (this.routeId) {
				try {
					const result = await this.axios.get(
						`/v1/routes/${this.routeId}/itineraries?itinerary_only=1`
					)
					const itineraries = result.data.data
					if (itineraries) {
						const itinerary = itineraries[0]
						tripStartTime = itinerary.trip_time
					}
				} catch (e) {

				}
			}
			this.tripStartTime = tripStartTime
		},
		showRoute(route)
		{
			this.activeRoute = route
			if (this.activeRoute && this.activeRoute.route_availability_days) {
				const availableDays = JSON.parse(
					this.activeRoute.route_availability_days
				)
				this.activeRoute.route_availability_days =
					Array.isArray(availableDays) && availableDays.length
						? availableDays
						: []
				this.activeRoute.availableDays =
					this.activeRoute.route_availability_days.length === 7
						? 'Everyday'
						: this.activeRoute.route_availability_days.join(', ')
			}
		},
		getPassengers()
		{
			this.fetchingPassengers = true

			this.axios.get(`/v1/routes/${this.activeRoute.id}/users`).then((res) =>
			{
				this.passengers = res.data
			})
		},
		getRouteDrivers()
		{
			this.fetchingRouteDrivers = true

			this.axios
				.get(`/v1/routes/${this.activeRoute.id}/drivers/`)
				.then(async (res) =>
				{
					this.routeDrivers = res.data.data || []
				})
				.finally(() => (this.fetchingRouteDrivers = false))
		},
		getBookings(corporateId = null)
		{
			const today = new Date()
			const datesInMonth = getDatesInMonth(today)

			const dateWeek = []
			for (let i = 1; i <= 7; i++) {
				/**
				 *  when using the getDay() method, a date that falls on a Sunday
				 * returns as 0, i.e Sunday being the default first day of the week.
				 * Because we want to Sunday the last day of the week in this case
				 * we need to add this logic that adds -6 only if getDay() returns 0
				 *  */
				const first =
					today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : i)
				const day = new Date(today.setDate(first)).toISOString().slice(0, 10)
				dateWeek.push(day)
			}
			this.dateWeek = dateWeek
			this.fetchingBookings = true
			const corpId = corporateId ? parseInt(corporateId) : null
			this.axios
				.post(`/v1/routes/${this.activeRoute.id}/bookings/${corpId}`, {
					booking_days: datesInMonth
				})
				.then((res) =>
				{
					this.bookings = res.data.data
					this.weeklyBookingsData = getWeeklyBookings(this.bookings)
					const currentWeek = getCurrentWeek()
					this.weeklyBookings = this.weeklyBookingsData[currentWeek - 1]
					this.currentPage = currentWeek
					this.totalRowCount =
						this.weeklyBookingsData.length * this.recordsPerPage
				})
				.finally(() => (this.fetchingBookings = false))
		},
		loadBookingForCurrentPage(page)
		{
			const pageIndex = page - 1
			this.weeklyBookings = this.weeklyBookingsData[pageIndex]
		},
		showRouteBooking(booking)
		{
			this.$router.push({
				name: 'ShowRouteBookings',
				params: {
					bookingInfo: `${booking.date}|${this.passengersFilter.corporateId
						? this.passengersFilter.corporateId
						: ''
						}`
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.badge.badge-purple {
	background-color: #7529ff;
	color: #fff;
}
</style>
