import { DoNotShowOnProd, openAsExternalUrl, currentUser } from '@/composables/core/permissions'
import FeatureFlagService, { FEATURE_FLAGS } from '@/utils/feature-flags'
import _ from 'lodash'
import { computed } from 'vue'

// const isProductionEnv = process.env.VUE_APP_API_URL === 'https://api.shuttlers.africa' || process.env.VUE_APP_API_URL === 'https://api.shuttlers.ng'
export const TOP_LEVEL_SIDEBAR_MENUS = [
  {
    title: 'Dashboard',
    routeName: 'Dashboard',
    description: '',
    iconPath: require('@/assets/img/icons/sidebar/home.svg'),
    children: null,
    modulePermission: ['ROUTES', 'CORPORATES', 'USERS', 'TRIPS', 'PARTNERS', 'DRIVERS', 'EVENTS', 'TRANSACTIONS', 'VEHICLES', 'REFERRALS', 'PROMOTIONS', 'ADMINCONFIG', 'STAFF']
  },
  {
    title: 'Route Search',
    routeName: 'route-searches.review',
    description: '',
    iconPath: null,
    rootPath: '/route-searches',
    feIcon: 'fe-compass',
    children: null,
    featureFlag: FEATURE_FLAGS.ROUTE_SEARCH,
    modulePermission: 'ROUTES'
  }
]

export const OPS_AND_MANAGEMENT_SIDEBAR_MENUS = [
  {
    title: 'Corporates',
    routeName: null,
    description: '',
    iconPath: require('@/assets/img/icons/sidebar/companies.svg'),
    rootPath: '/companies',
    modulePermission: 'CORPORATES',
    children: [
      {
        title: 'Insights',
        routeName: 'CompanyInsights',
        description: 'Insights into company requests',
        isExternal: true,
        calling_function: ()=> openAsExternalUrl('/companies/insights'),
      },
      {
        title: 'Companies',
        routeName: 'Companies',
        description: '',
        excludedPaths: ['/companies/insights']
      },
      
      ...[(DoNotShowOnProd() ? {
        title: 'Shuttle request',
        routeName: 'Shuttle Requests',
        description: '',
        // excludedPaths: ['/request']
      } : {})]
    
    ]
  },
  {
    title: 'Users',
    routeName: null,
    description: '',
    iconPath: require('@/assets/img/icons/sidebar/users.svg'),
    rootPath: '/users',
    modulePermission: 'USERS',
    children: [
      {
        title: 'Insights',
        routeName: 'UsersInsight',
        description: 'Insights into company requests'
      },
      {
        title: 'Manage Users',
        routeName: 'Users',
        description: '',
        excludedPaths: ['/users/insights']
      },
      {
        title: 'Batch Bookings',
        routeName: 'ListBatchBookings',
        description: ''
      }
    ]
  },


  {
    title: 'Commute',
    routeName: null,
    description: '',
    iconPath: require('@/assets/img/icons/sidebar/charter.svg'),
    rootPath: '/routes',
    modulePermission: ['TRIPS', 'ROUTES', 'CHARTER'],
    children: [
      {
        title: 'Trips',
        routeName: 'Trips',
        description: 'Insights into company requests',
        excludedPaths: ['/trips/track-vehicle'],
        isExternal: currentUser.value?.ab_testing_role === 'B' ? true : false,
        calling_function: ()=> openAsExternalUrl('/trips/type/active'),
      },
      {
        title: 'Track Vehicle',
        routeName: 'NewTrackVehicle',
        description: ''
      },
      {
        title: 'Routes',
        routeName: 'RoutesList',
        description: 'All routes',
        excludedPaths: ['/routes/suggested-routes']
      },
      {
        title: 'Suggested routes',
        routeName: 'SuggestedRoutes',
        description: ''
      },
      {
        title: 'Waitlist',
        routeName: 'WaitList',
        description: ''
      },
      {
        title: 'Rentals',
        routeName: 'ChartersList',
        description: 'Handle shuttlers charter requests'
      },
        {
          title: 'Emergency report',
          isExternal: true,
          calling_function: ()=> openAsExternalUrl('/trips/sos'),
        routePath: null,
        description: 'Handle shuttlers sos requests'
      }
    ]
  },
  
  {
    title: 'Vehicle Partners',
    routeName: null,
    description: '',
    iconPath: require('@/assets/img/icons/sidebar/vehicle_partner.svg'),
    rootPath: '/partners',
    modulePermission: 'PARTNERS',
    children: [
      {
        title: 'Partners List',
        routeName: 'PartnersList',
        description: 'Vechiles Partners List'
      },
      {
        title: 'Payout',
        routeName: 'Payout',
        description: 'Vechiles Partners payout'
      },
      {
          title: 'Notify',
          isExternal: true,
          calling_function: ()=> openAsExternalUrl('/partners/notify'),
        routePath: null,
        description: 'Handle shuttlers Partners notification'
      }
    ]
  },
  {
    title: 'Drivers',
    routeName: null,
    description: '',
    rootPath: '/drivers',
    iconPath: require('@/assets/img/icons/sidebar/users.svg'),
    modulePermission: 'DRIVERS',
    children: [
      {
        title: 'Manage',
        routeName: 'DriversList',
        description: ''
      },
    ]
  },
  {
    title: 'Events',
    routeName: 'EventList',
    description: '',
    iconPath: require('@/assets/img/icons/sidebar/fleet management.svg'),
    rootPath: '/events',
    children: null,
    modulePermission: ['EVENTS'],
  },
  {
    title: 'Transactions',
    routeName: 'transactions.index',
    description: '',
    iconPath: require('@/assets/img/icons/sidebar/payment.svg'),
    children: null,
    modulePermission: 'TRANSACTIONS'
  },
  {
    title: 'Fleet',
    routeName: 'FleetVehicleList',
    description: '',
    iconPath: require('@/assets/img/icons/sidebar/charter.svg'),
    rootPath: '/fleet',
    modulePermission: 'VEHICLES'
  },
  {
    title: 'Referral Codes',
    routeName: 'ListReferralCodes',
    description: '',
    iconPath: null,
    feIcon: 'fe-tag',
    children: null,
    modulePermission: 'PROMOTIONS'
  },
  {
    title: 'Promo Codes',
    routeName: 'ListPromoCode',
    description: '',
    iconPath: null,
    feIcon: 'fe-tag',
    modulePermission: 'PROMOTIONS',
    children: null
  },

  {
    title: 'Campaigns',
    routeName: null,
    description: '',
    iconPath: require('@/assets/img/icons/sidebar/vehicle_partner.svg'),
    rootPath: '/games&campaigns',
    modulePermission: 'CAMPAIGNS',
    children: [
      {
        title: 'Valentine',
        routeName: 'ValentineCampaignList',
        description: 'Valentine management'
      },
              {
          title: 'Rewards',
          isExternal: true,
          calling_function: ()=> openAsExternalUrl('/campaigns/rewards'),
        routePath: null,
        description: 'Handle shuttlers sos requests'
      },
      {
        title: 'CMS Banners',
        routeName: 'BannerConfig',
        description: 'CMS promotional banner',
        excludedPaths: ['/games&campaigns/valentine']
      }
    ]
  }
].filter((i) => !!i)


export const ADMIN_SIDEBAR_MENUS = computed(() => {
  return [
    {
      title: 'Admins',
      routeName: 'StaffList',
      description: '',
      iconPath: require('@/assets/img/icons/sidebar/admin.svg'),
      children: null,
      rootPath: '/admin',
      modulePermission: 'STAFF',
      requiredAdmin: true
    },
    {
      title: 'Configure',
      routeName: null,
      description: '',
      iconPath: require('@/assets/img/icons/sidebar/configure.svg'),
      rootPath: '/configuration',
      modulePermission: ['ADMINCONFIG', 'INSPECTION'],
      children: [
        {
          title: 'Trip Rating Settings',
          routeName: 'TripRatingSettings',
          description: 'Configure and manage trip rating parameters'
        },
        {
          title: 'Vehicle',
          routeName: 'CategoryManagement',
          description: 'Manage vehicle categories'
        },
        {
          title: 'Amenities',
          routeName: 'VehicleAmenitiesSettings',
          description: 'Manage vehicle amenities'
        },
        {
          title: 'Route Price Control',
          routeName: 'PricingControls',
          description: 'Manage route pricing centrally',
          isExternal: DoNotShowOnProd(),
          calling_function: ()=> openAsExternalUrl('/configuration/pricing/pricing-control'),
        }
      ]
    }
  ]
})

export function getSidebarMenus(menus, permissionsModules, userRole)
{
  return menus
    .filter((menu) =>
      menu.featureFlag
        ? FeatureFlagService.isFlagEnabled(menu.featureFlag)
        : true
    ).filter((menu) =>
    {
      if (Array.isArray(permissionsModules)) {
        if (menu.title === 'Dashboard' || menu.title === 'Events') return true
        const whiteListRoles = ['admin', 'super_admin']
        if (whiteListRoles.includes(userRole)) return true

        if (userRole === 'user' && menu?.requiredAdmin === true) return false
        for (const permission of permissionsModules) {
          if (whiteListRoles.includes(userRole)) {
            return true
          }

          if (permission === menu.modulePermission) {
            return true
          }

          if (Array.isArray(menu.modulePermission)) {
            if (menu.modulePermission.includes(permission)) {
              return true
            }
          }
        }
      } else {
        return true
      }
    })
    .map((menu) =>
    {
      menu.isOpen = false
      return menu
    })
}
